import "./jquery-bootstrap";
import "./influxframework/class.js";
import "./influxframework/polyfill.js";
import "./lib/moment.js";
import "./influxframework/provide.js";
import "./influxframework/translate.js";
import "./influxframework/form/formatters.js";
import "./influxframework/format.js";
import "./influxframework/utils/number_format.js";
import "./influxframework/utils/utils.js";
import "./influxframework/utils/common.js";
import "./influxframework/ui/messages.js";
import "./influxframework/utils/pretty_date.js";
import "./influxframework/utils/datetime.js";
import "./influxframework/microtemplate.js";
import "./influxframework/query_string.js";

import "./influxframework/upload.js";

import "./influxframework/model/meta.js";
import "./influxframework/model/model.js";
import "./influxframework/model/perm.js";

import "./bootstrap-4-web.bundle";

import "../../website/js/website.js";
import "./influxframework/socketio_client.js";
